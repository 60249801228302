<template>
  <span class="pauseIcon"
    ><span class="clock"><icon id="clock"/></span
    ><span class="icon"><icon :id="id"/></span
  ></span>
</template>
<script>
export default {
  components: {},
  props: {
    id: {
      type: String,
      default() {
        return "coffee-mug";
      }
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass">
@import '@/assets/sass/variables.sass'
@import '@/assets/sass/includes/pauseIcon.sass'
</style>
